import Carousel from 'react-bootstrap/Carousel';
import img2 from "../img/carousel-1.jpg"
import img1 from "../img/carousel-2.webp"




function MyCarousel() {
  const Images = [img1, img2]
  return (
    <>
      <Carousel variant='dark' className='rounded-0 carouselUpcoming' autoPlay={true}
      interval={4000}
      controls={true}
      indicators={true} >
      {Images.map(image=>(<Carousel.Item className="w-100 h-100 position-relative">
        <img
        style={{width:'100%',height:"100%",objectFit:'contain'}}
        clssName = "d-block h-100" 
        src={image}
        alt="upeventcarousel"
        />
         <div className="carousel-title">
         <div className="container">
                        <div className="row justify-content-start">
                            <div className="col-10 col-lg-8">
                                <h5 className="text-white text-uppercase mb-3 animated slideInDown">Your Trusted Source for Building Success</h5>
                                <h1 className="display-3 text-white animated slideInDown mb-4">Premium Building Materials & Modern Solutions</h1>
                                <p className="fs-6 fw-medium text-white mb-4 pb-2">At Innov8, we know that innovative, high-quality materials are key to creating exceptional spaces. Whether you're designing a state-of-the-art structure or enhancing a modern home, we provide the Aluminum & Glass Accessories, Modern LED Lighting, Automated Pergolas, Slim Partitions, and Smart Glass you need to achieve outstanding results.</p>
                                <a href="/about" className="btn btn-danger py-md-3 px-md-5 me-3 animated slideInLeft">Read More</a>
                                <a href="/contact" className="btn btn-secondary py-md-3 px-md-5 animated slideInRight">Free Quote</a>
                            </div>
                        </div>
                    </div>
            </div>
      </Carousel.Item>))}
    </Carousel>
    </>
  );
}

export default MyCarousel;
