import React from 'react'
import pic from '../img/louver1.jpg'
import { Carousel } from 'react-bootstrap';
import material1 from '../img/louver2.jpg';
import material2 from '../img/louver3.jpg';
import material3 from '../img/louver4.jpg';

const Louvers = () => {
  return (
    <>
            <div style={{ position: "relative", width: "100%" }}>
                <img style={{ height: "70vh", width: "100%" }} src={pic} alt='' />
                <h1 style={{
                    fontSize: "50px",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    color: "white",  // Optional: make sure the text is visible over the image
                    backgroundColor: "rgba(0, 0, 0, 0.5)",  // Optional: add a background for better readability
                    padding: "10px", // Optional: add some padding around the text
                }}>Standard and Sand Trap Louvers</h1>
            </div>

            <section style={{ margin: "40px" }}>
                <h2 style={{ fontWeight: "700" }}>Product Overview</h2>
                <p style={{ fontSize: "18px", lineHeight: "1.6" }}>
                The sand trap louver is used as pre-filter for the protection of air conditioning plants in areas exposed to extreme levels of industrial pollution. It has a degree of separation of sand and large dust particles, even in cases of high dust concentrations. 
                </p>
            </section>
            <div className='building justify-content-center' style={{ width: "100%", display: "flex" }}>
                <Carousel 
                style={{ width: "55%" }}
                interval={3000}
                >
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={material1}
                            alt="First slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={material2}
                            alt="First slide"
                        />
                    </Carousel.Item>

                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={material3}
                            alt="Second slide"
                        />
                    </Carousel.Item>
                </Carousel>
            </div>

            <section style={{ margin: "40px" }}>
                <p style={{ fontSize: "18px", lineHeight: "1.6" }}>
                The vertically arranged sections and holes for sand drainage ensure the sand trap louvre is self-cleaning and maintenance-free. The sand trap louvre is designed to separate large particles at low air velocities, thus avoiding excessive dust loading on conventional plant filters. It is not intended as a substitute for conventional supply air filtration plant.
                </p>
                <p style={{ fontSize: "18px", lineHeight: "1.6" }}>
                If you need more detailed information about installation, specifications, or specific brands, let us know.
                </p>
            </section>

        </>
  )
}

export default Louvers