import React from 'react'
import service1 from "../img/service-1.jpg"
import service2 from "../img/service-2.jpg"
import service3 from "../img/service-3.jpg"
import service4 from "../img/service-4.jpg"
import service5 from "../img/service-5.png"
import service6 from "../img/service-6.png"
import service7 from "../img/service-7.webp"



function Services() {
  return (
    <>
        {/* <!-- Service Start --> */}
    <div className="container-xxl py-5">
        <div className="container py-5">
            <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                <h6 className="text-secondary text-uppercase">Our Products</h6>
                <h1 className="mb-5">Explore Our Products</h1>
            </div>
            <div className="row g-4">
            <div className="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.5s">
                    <div className="service-item p-4">
                        <div className="overflow-hidden mb-4">
                            <img className="img-fluid" src={service7} alt=""/>
                        </div>
                        <h4 className="mb-3">Building Material</h4>
                        <p>Discover our premium range of building materials, designed to meet the highest standards of quality and durability. From foundations to finishing touches, our products are engineered to ensure your builds are solid, sustainable, and built to last.</p>
                        <a className="btn-slide mt-2" href="/building"><i className="fa fa-arrow-right"></i><span>Read More</span></a>
                    </div>
                </div>
                <div className="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.3s">
                    <div className="service-item p-4">
                        <div className="overflow-hidden mb-4">
                            <img className="img-fluid" src={service1} alt=""/>
                        </div>
                        <h4 className="mb-3">Aluminium & Glass Accessories</h4>
                        <p>Enhance your projects with our top-quality Aluminum & Glass Accessories, designed for durability and modern aesthetics. Perfect for both functional and decorative applications, these products bring a sleek, contemporary touch to any space.</p>
                        <a className="btn-slide mt-2" href="/aluminium"><i className="fa fa-arrow-right"></i><span>Read More</span></a>
                    </div>
                </div>
                <div className="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.5s">
                    <div className="service-item p-4">
                        <div className="overflow-hidden mb-4">
                            <img className="img-fluid" src={service2} alt=""/>
                        </div>
                        <h4 className="mb-3">Lighting</h4>
                        <p>Illuminate your spaces with our energy-efficient Modern LED Lighting solutions. Designed to blend style with functionality, our lighting options offer superior brightness, longevity, and a contemporary aesthetic that complements any environment.</p>
                        <a className="btn-slide mt-2" href="/lighting"><i className="fa fa-arrow-right"></i><span>Read More</span></a>
                    </div>
                </div>
                <div className="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.7s">
                    <div className="service-item p-4">
                        <div className="overflow-hidden mb-4">
                            <img className="img-fluid" src={service3} alt=""/>
                        </div>
                        <h4 className="mb-3">Bi Fold Doors</h4>
                        <p>Maximize your space with our stylish and functional Bi-Fold Doors. Designed for smooth operation and modern appeal, these doors provide a seamless transition between indoor and outdoor areas, enhancing both the aesthetics and utility of your spaces.</p>
                        <a className="btn-slide mt-2" href="/doors"><i className="fa fa-arrow-right"></i><span>Read More</span></a>
                    </div>
                </div>
                <div className="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.3s">
                    <div className="service-item p-4">
                        <div className="overflow-hidden mb-4">
                            <img className="img-fluid" src={service4} alt=""/>
                        </div>
                        <h4 className="mb-3">Modern Motorized Pergola</h4>
                        <p>Transform your outdoor spaces with our state-of-the-art Pergola and Kabana solutions. Combining elegance with functionality, these structures offer a stylish retreat that enhances the comfort and appeal of any outdoor area, perfect for both relaxation and entertainment.</p>
                        <a className="btn-slide mt-2" href="/pergola"><i className="fa fa-arrow-right"></i><span>Read More</span></a>
                    </div>
                </div>
                <div className="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.5s">
                    <div className="service-item p-4">
                        <div className="overflow-hidden mb-4">
                            <img className="img-fluid" src={service5} alt=""/>
                        </div>
                        <h4 className="mb-3">Standard and Sand Trap Louvers</h4>
                        <p>Optimize airflow and protect your spaces with our high-performance Standard and Sand Trap Louvers. Engineered for durability and efficiency, these louvers are ideal for filtering dust and debris while maintaining effective ventilation in challenging environments.</p>
                        <a className="btn-slide mt-2" href="/louvers"><i className="fa fa-arrow-right"></i><span>Read More</span></a>
                    </div>
                </div>
                <div className="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.7s">
                    <div className="service-item p-4">
                        <div className="overflow-hidden mb-4">
                            <img className="img-fluid"  src={service6} alt="sgp"/>
                        </div>
                        <h4 className="mb-3">Slim Glass Partitions</h4>
                        <p>Enhance your spaces with our Slim Glass Partitions, designed for modern aesthetics and superior functionality. Ideal for creating sleek, sophisticated interiors.</p>
                        <a className="btn-slide mt-2" href="/glass-partitions"><i className="fa fa-arrow-right"></i><span>Read More</span></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- Service End --> */}

    </>
  )
}

export default Services