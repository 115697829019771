import React from 'react'
import MyCarousel from '../components/MyCarousel'
import About from './About'
import Facts from '../components/Facts'
import Services from '../components/Services'
import Features from '../components/Features'
import Quote from '../components/Quote'
import Testimonial from '../components/Testimonial'
import ContactButton from '../components/ContactButton'

function Home() {

        return (
            <>
                {/* <!-- Spinner Start --> */}
                {/* <div id="spinner" className="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
                <div className="spinner-grow text-danger" style={{width: '3rem', height: '3rem'}} role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div> */}
                {/* <!-- Spinner End --> */}

                <ContactButton />

                <MyCarousel />
                <About />
                <Facts />
                <Services />
                <Features />
                <Quote />
                <Testimonial />
            </>
        )
    }

    export default Home