import React from 'react'
import pic from '../img/service-7.webp'
import { Carousel } from 'react-bootstrap';
import material1 from '../img/building4.png';
import material2 from '../img/building1.png';
import material3 from '../img/building3.png';
import material4 from '../img/building2.png';


const Building = () => {
    return (
        <>
            <div style={{ position: "relative", width: "100%" }}>
                <img style={{ height: "70vh", width: "100%" }} src={pic} alt='' />
                <h1 style={{
                    fontSize: "50px",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    color: "white",  // Optional: make sure the text is visible over the image
                    backgroundColor: "rgba(0, 0, 0, 0.5)",  // Optional: add a background for better readability
                    padding: "10px", // Optional: add some padding around the text
                }}>BUILDING MATERIALS</h1>
            </div>

            <section style={{ margin: "40px" }}>
                <h2 style={{ fontWeight: "700" }}>Product Overview</h2>
                <p style={{ fontSize: "18px", lineHeight: "1.6" }}>
                    Our building materials are crafted to meet the highest standards of quality and durability, ensuring your projects stand the test of time. Whether you're working on residential or commercial construction, our materials offer superior strength and reliability. From foundational elements to finishing touches, trust our products to provide the stability and performance needed for every build.
                </p>
            </section>
            <div className='building justify-content-center' style={{ width: "100%", display: "flex" }}>
                <Carousel 
                style={{ width: "55%" }}
                interval={3000}
                >
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={material1}
                            alt="First slide"
                        />
                        <Carousel.Caption style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            color: 'white',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            padding: '10px',
                            borderRadius: '5px',
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}>
                            <h3>Construction Supplies</h3>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={material2}
                            alt="First slide"
                        />
                        <Carousel.Caption style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            color: 'white',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            padding: '10px',
                            borderRadius: '5px',
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}>
                            <h3>Insulation Materials</h3>
                        </Carousel.Caption>
                    </Carousel.Item>

                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={material3}
                            alt="Second slide"
                        />
                        <Carousel.Caption style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            color: 'white',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            padding: '10px',
                            borderRadius: '5px',
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}>
                            <h3>Plumbing Materials</h3>
                        </Carousel.Caption>
                    </Carousel.Item>

                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={material4}
                            alt="Third slide"
                        />
                        <Carousel.Caption style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            color: 'white',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            padding: '10px',
                            borderRadius: '5px',
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}>
                            <h3>Roofing Materials</h3>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </div>

            <section style={{ margin: "40px" }}>
                <p style={{ fontSize: "18px", lineHeight: "1.6" }}>
                Innvo8 General Trading is your trusted partner for high-quality building materials. As a leader in the trading of building materials, we offer a wide range of products, including insulation materials, roofing materials, and plumbing supplies.
                </p>
                <p style={{ fontSize: "18px", lineHeight: "1.6" }}>
                Our commitment to quality and reliability sets us apart from our competitors. We work tirelessly to source only the best materials from reputable manufacturers and suppliers, ensuring that your construction projects are built to the highest standards. 
                </p>
                <p style={{ fontSize: "18px", lineHeight: "1.6" }}>
                At Innov8, we understand the importance of sustainability in the construction industry. That’s why we offer a range of eco-friendly and sustainable materials to help you build projects that are environmentally friendly and reduce carbon emissions. Our team of experts is knowledgeable and experienced in the industry, and we work closely with our customers to understand their specific needs and requirements. Whether you’re a contractor, builder, or homeowner, we’re committed to providing you with personalized service and customized solutions to meet your building material needs.
                </p>
                <p style={{ fontSize: "18px", lineHeight: "1.6" }}>
                We offer competitive prices and a hassle-free experience, with efficient delivery that ensures on-time delivery of ordered products. Our skilled logistics team ensures that your products are delivered safely and securely. At Innov8, we take pride in our reputation for quality, reliability, and professionalism. Our commitment to exceptional customer service combined with our vast selection of products and state-of-the-art facilities make us the premier choice for trading building materials.
                </p>
            </section>

        </>
    )
}

export default Building