import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMessage, faPhone } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

const ContactButton = () => {
    const [showOptions, setShowOptions] = useState(false);

    const toggleOptions = () => {
        setShowOptions(!showOptions);
    };

    return (
        <div className="contact-container">
            <div className="contact-button" onClick={toggleOptions}>
            <FontAwesomeIcon icon={faMessage} color="white" />
            </div>
            {showOptions && ( 
                <div className="contact-options">
                    <a href="tel:+971524188072" className="contact-option"><FontAwesomeIcon icon={faPhone} size="1x" color="white" /></a>
                    <a href="https://wa.me/+971524188072" className="contact-option" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faWhatsapp} size="1x" color="white" /></a>
                </div>
            )}
        </div>
    );
};

export default ContactButton;
