import React from 'react'
import pic from '../img/glass1.jpg'
import { Carousel } from 'react-bootstrap';
import material1 from '../img/glass2.jpg';
import material2 from '../img/glass3.jpg';

const Glass = () => {
  return (
    <>
            <div style={{ position: "relative", width: "100%" }}>
                <img style={{ height: "70vh", width: "100%" }} src={pic} alt='' />
                <h1 style={{
                    fontSize: "50px",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    color: "white",  // Optional: make sure the text is visible over the image
                    backgroundColor: "rgba(0, 0, 0, 0.5)",  // Optional: add a background for better readability
                    padding: "10px", // Optional: add some padding around the text
                }}>Office Glass Partitions</h1>
            </div>

            <section style={{ margin: "40px" }}>
                <h2 style={{ fontWeight: "700" }}>Product Overview</h2>
                <p style={{ fontSize: "18px", lineHeight: "1.6" }}>
                Our Office glass partitions are innovative & quality tested to provide transparent privacy solutions to any work place. 
                </p>
            </section>
            <div className='building justify-content-center' style={{ width: "100%", display: "flex" }}>
                <Carousel 
                style={{ width: "55%" }}
                interval={3000}
                >
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={material1}
                            alt="First slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={material2}
                            alt="First slide"
                        />
                    </Carousel.Item>
                </Carousel>
            </div>

            <section style={{ margin: "40px" }}>
                <p style={{ fontSize: "18px", lineHeight: "1.6" }}>
                With products that match international standards in design and functionality this is the most preferred brand with repeated orders from our clients for the past 10 years. Glass partitions are available in a wide range of styles, finishes, functions and color variants to complement and enhance a multitude of projects.
                </p>
                <p style={{ fontSize: "18px", lineHeight: "1.6" }}>
                Our products include:
                </p>
                <ol>
                    <li>Atrium Wall Partition </li>
                    <li>Frameless Wall Partitions </li>
                    <li>Moveable Walls Partitions</li>
                    <li>Modular Wall Partition</li>
                    <li>Freestanding Glass Partition</li>
                    <li>Acoustic Glass Partition</li>
                </ol>
            </section>

        </>
  )
}

export default Glass