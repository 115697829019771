import React from 'react'

const Footer = () => {
  return (
    <>
            {/* <!-- Footer Start --> */}
    <div className="container-fluid bg-dark text-light footer pt-5 wow fadeIn" data-wow-delay="0.1s" style={{marginTop: '6rem'}}>
        <div className="container py-5">
            <div className="row g-5">
                <div className="col-lg-3 col-md-6">
                    <h4 className="text-light mb-4">Address</h4>
                    <p className="mb-2"><i className="fa fa-map-marker-alt me-3"></i>Block B - B39-033, Sharjah Research Technology and Innovation Park, Sharjah</p>
                    <p className="mb-2"><i className="fa fa-phone-alt me-3"></i>+971 6 805 4979 / +971 52 418 8072</p>
                    <p className="mb-2"><i className="fa fa-envelope me-3"></i>info@theinnov8.com</p>
                    <div className="d-flex pt-2">
                        <a className="btn btn-outline-light btn-social" href="https://www.instagram.com/innov8.uae?utm_source=qr&igsh=MXdtMW1id2tnendqeA=="><i className="fab fa-instagram"></i></a>
                        <a className="btn btn-outline-light btn-social" href="https://www.facebook.com/profile.php?id=61559080456957"><i className="fab fa-facebook-f"></i></a>
                        {/* <a className="btn btn-outline-light btn-social" href="/"><i className="fab fa-youtube"></i></a>*/}
                        <a className="btn btn-outline-light btn-social" href="https://www.linkedin.com/company/innov8trd/"><i className="fab fa-linkedin-in"></i></a> 
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <h4 className="text-light mb-4">Products</h4>
                    <a className="btn btn-link" href="/building">Construction Materials</a>
                    <a className="btn btn-link" href="/aluminium">Aluminium & Glass Accessories</a>
                    <a className="btn btn-link" href="/lighting">Lighting</a>
                    <a className="btn btn-link" href="/doors">Bi Fold Doors</a>
                    <a className="btn btn-link" href="/pergola">Modern Motorized Pergola</a>
                    <a className="btn btn-link" href="/louvers">Louvers</a>
                    <a className="btn btn-link" href="/glass-partitions">Slim Glass Partitions</a>

                </div>
                <div className="col-lg-3 col-md-6">
                    <h4 className="text-light mb-4">Quick Links</h4>
                    <a className="btn btn-link" href="/about">About Us</a>
                    <a className="btn btn-link" href="/contact">Contact Us</a>
                    <a className="btn btn-link" href="/services">Our Services</a>
                    {/* <a className="btn btn-link" href="/">Terms & Condition</a> */}
                    <a className="btn btn-link" href="/contact">Support</a>
                </div>
                {/* <div className="col-lg-3 col-md-6">
                    <h4 className="text-light mb-4">Newsletter</h4>
                    <p>Dolor amet sit justo amet elitr clita ipsum elitr est.</p>
                    <div className="position-relative mx-auto" style="max-width: 400px;">
                        <input className="form-control border-0 w-100 py-3 ps-4 pe-5" type="text" placeholder="Your email" />
                        <button type="button" className="btn btn-danger py-2 position-absolute top-0 end-0 mt-2 me-2">SignUp</button>
                    </div>
                </div> */}
            </div>
        </div>
        <div className="container">
            <div className="copyright">
                <div className="row">
                    <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                        &copy; <a className="border-bottom" href="/">INNOV8</a>, All Right Reserved.
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- Footer End --> */}

    </>
  )
}

export default Footer