import React from 'react'
import feature from "../img/feature.jpg"
function Features() {
  return (
    <>
        {/* <!-- Feature Start --> */}
    <div className="container-fluid overflow-hidden py-5 px-lg-0">
        <div className="container feature py-5 px-lg-0">
            <div className="row g-5 mx-lg-0">
                <div className="col-lg-6 feature-text wow fadeInUp" data-wow-delay="0.1s">
                    <h6 className="text-secondary text-uppercase mb-3">Our Features</h6>
                    <h1 className="mb-5">Leading the Industry with Innovative Building Solutions</h1>
                    <div className="d-flex mb-5 wow fadeInUp" data-wow-delay="0.3s">
                        <i className="fa fa-globe text-danger fa-3x flex-shrink-0"></i>
                        <div className="ms-4">
                            <h5>Top-Quality Products</h5>
                            <p className="mb-0">We provide only the best in Aluminum & Glass Accessories, Modern LED Lighting, Automated Pergolas, Slim Partitions, and Smart Glass. Our commitment to quality ensures that your projects are built to last with materials you can trust.</p>
                        </div>
                    </div>
                    <div className="d-flex mb-5 wow fadeIn" data-wow-delay="0.5s">
                        <i className="fa fa-shipping-fast text-danger fa-3x flex-shrink-0"></i>
                        <div className="ms-4">
                            <h5>Reliable Delivery</h5>
                            <p className="mb-0">We understand the importance of timing in construction. That’s why we pride ourselves on delivering your orders on time, every time, ensuring that your project stays on schedule.</p>
                        </div>
                    </div>
                    <div className="d-flex mb-0 wow fadeInUp" data-wow-delay="0.7s">
                        <i className="fa fa-headphones text-danger fa-3x flex-shrink-0"></i>
                        <div className="ms-4">
                            <h5>Expert Support, Anytime</h5>
                            <p className="mb-0">Our knowledgeable team is available 24/7 to assist you with any questions or concerns. Whether you need advice on product selection or technical support, we're just a call away.</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 pe-lg-0 wow fadeInRight" data-wow-delay="0.1s" style={{minHeight: '400px'}}>
                    <div className="position-relative h-100">
                        <img className="position-absolute img-fluid w-100 h-100" src={feature} style={{objectFit: 'cover'}} alt=""/>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- Feature End --> */}
    </>
  )
}

export default Features