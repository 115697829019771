import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

function Quote() {
    return (
        <>

            {/* <!-- Quote Start --> */}
            <div className="container-xxl py-5">
                <div className="container py-5">
                    <div className="row g-5 align-items-center">
                        <div className="col-lg-5 wow fadeInUp" data-wow-delay="0.1s">
                            <h6 className="text-secondary text-uppercase mb-3">Get A Quote</h6>
                            <h1 className="mb-5">Request A Free Quote!</h1>
                            <p className="mb-5">Ready to bring your project to life? Whether you need Aluminum & Glass Accessories, Modern LED Lighting, or any of our innovative building solutions, we’re here to help. Simply fill out the form, and our team will provide you with a customized quote tailored to your specific needs. Let us partner with you to ensure your project is a success.</p>
                            <div className="d-flex align-items-center">
                                <i className="fa fa-headphones fa-2x flex-shrink-0 bg-danger p-3 text-white"></i>
                                <div className="ps-4">
                                    <h6>Call for any query!</h6>
                                    <h3 className="text-danger m-0">+971 52 418 8072</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="bg-light text-center p-5 wow fadeIn" data-wow-delay="0.5s">
                                <form>
                                    <div className="row g-3">
                                        <div className="col-12 col-sm-6">
                                            <input type="text" className="form-control border-0" placeholder="Your Name" style={{ height: '55px' }} />
                                        </div>
                                        <div className="col-12 col-sm-6">
                                            <input type="email" className="form-control border-0" placeholder="Your Email" style={{ height: '55px' }} />
                                        </div>
                                        <div className="col-12 col-sm-6">
                                            <input type="text" className="form-control border-0" placeholder="Your Mobile" style={{ height: '55px' }} />
                                        </div>
                                        <div className="col-12 col-sm-6">
                                            <select className="form-select border-0" style={{ height: '55px' }}>
                                                <option selected>Select A Product</option>
                                                <option value="1">Lighting</option>
                                                <option value="2">Louvers</option>
                                                <option value="2">Bifold doors</option>
                                                <option value="2">Pergola and Cabana</option>
                                                <option value="3">Aluminium and glass accessories</option>
                                            </select>
                                        </div>
                                        <div className="col-12">
                                            <textarea className="form-control border-0" placeholder="Special Note"></textarea>
                                        </div>
                                        <div className="col-12">
                                            <a href="https://wa.me/+971585906121" target="_blank" rel="noopener noreferrer">
                                                <button className="btn btn-danger w-100 py-3" type="submit"><a className='btn' href="https://wa.me/+971524188072" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faWhatsapp} style={{ marginRight: "10px" }} />Contact Us</a></button>
                                            </a>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Quote End --> */}


        </>
    )
}

export default Quote