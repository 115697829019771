import React from 'react';
import { Carousel } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-regular-svg-icons';
import { faQuoteRight } from '@fortawesome/free-solid-svg-icons';

const testimonials = [
  {
    name: 'John Doe',
    profession: 'Architect',
    text: 'The products from this company are top-notch. They really help me deliver high-quality results for my clients.',
  },
  {
    name: 'Jane Smith',
    profession: 'Interior Designer',
    text: 'I’ve been using their materials for years, and I’m always impressed by their durability and style.',
  },
  {
    name: 'Robert Brown',
    profession: 'Contractor',
    text: 'Their customer service is excellent, and the products are always delivered on time.',
  },
  {
    name: 'Emily Johnson',
    profession: 'Builder',
    text: 'The slim partitions are a game changer. They’re easy to install and look great in any space.',
  },
];

const Testimonial = () => {
  return (
    <>
      {/* Testimonial Start */}
      <div className="container-xxl py-5">
        <div className="container py-5">
          <div className="text-center">
            <h6 className="text-secondary text-uppercase">Testimonial</h6>
            <h1 className="mb-0">Our Clients Say!</h1>
          </div>
          <Carousel className="mt-5 custom-carousel-indicators" indicators={true} controls={true}>
            {testimonials.map((testimonial, index) => (
              <Carousel.Item key={index}>
                <div className="testimonial-item p-4 my-5 text-center position-relative">
                  <FontAwesomeIcon icon={faQuoteRight} size="3x" className="text-light position-absolute top-0 end-0 mt-n3 me-4" />
                  <div className="d-flex justify-content-center align-items-end mb-4">
                    <FontAwesomeIcon icon={faUserCircle} size="3x" style={{ color: '#ccc' }} />
                    <div className="ms-4">
                      <h5 className="mb-1">{testimonial.name}</h5>
                      <p className="m-0">{testimonial.profession}</p>
                    </div>
                  </div>
                  <p className="mb-0">{testimonial.text}</p>
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
      </div>
      {/* Testimonial End */}
    </>
  );
};

export default Testimonial;
