import React from 'react'
import pic from '../img/service-2.jpg'
import { Carousel } from 'react-bootstrap';
import material1 from '../img/light1.jpg';
import material2 from '../img/light2.jpg';
import material3 from '../img/light3.jpg';
import material4 from '../img/light4.jpg';
import material5 from '../img/light5.webp';


const Lighting = () => {

    return (
        <>
            <div style={{ position: "relative", width: "100%" }}>
                <img style={{ height: "70vh", width: "100%" }} src={pic} alt='' />
                <h1 style={{
                    fontSize: "50px",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    color: "white",  // Optional: make sure the text is visible over the image
                    backgroundColor: "rgba(0, 0, 0, 0.5)",  // Optional: add a background for better readability
                    padding: "10px", // Optional: add some padding around the text
                }}>Lighting</h1>
            </div>

            <section style={{ margin: "40px" }}>
                <h2 style={{ fontWeight: "700" }}>Product Overview</h2>
                <p style={{ fontSize: "18px", lineHeight: "1.6" }}>
                    <strong>High Performance LED Luminaires </strong> <br />
                    Illuminate your spaces with our energy-efficient Modern LED Lighting solutions. Designed to blend style with functionality, our lighting options offer superior brightness, longevity, and a contemporary aesthetic that complements any environment.
                </p>
            </section>
            <div className='building justify-content-center' style={{ width: "100%", display: "flex" }}>
                <Carousel
                    style={{ width: "55%" }}
                    interval={3000}
                >
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={material1}
                            alt="First slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={material2}
                            alt="First slide"
                        />
                    </Carousel.Item>

                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={material3}
                            alt="Second slide"
                        />
                    </Carousel.Item>

                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={material4}
                            alt="Third slide"
                        />
                    </Carousel.Item>

                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={material5}
                            alt="Third slide"
                        />
                    </Carousel.Item>
                </Carousel>
            </div>

            <section style={{ margin: "40px" }}>
                <p style={{ fontSize: "18px", lineHeight: "1.6" }}>
                We provide high-quality LED lights and accessories for all your lighting needs. Our commitment is to provide our clients with the most innovative LED products, excellent customer service, and competitive pricing.
                </p>
                <p style={{ fontSize: "18px", lineHeight: "1.6" }}>
                Our team of experienced professionals understands the importance of proper lighting and how it can affect the overall ambiance and mood of any living or workspace. That’s why we are dedicated to bringing you the best LED lighting products and accessories, designed to enhance and transform your space, while also providing energy efficiency and cost savings.
                </p>
                <p style={{ fontSize: "18px", lineHeight: "1.6" }}>
                We understand that each project is unique, and we offer personalized services to ensure that you get the best lighting solution to fit your space. Our team of professionals can provide guidance on how best to use our LED products to achieve the perfect lighting, tailored to fit your vision and specific needs.
                </p>
                <p style={{ fontSize: "18px", lineHeight: "1.6" }}>
                We also have a comprehensive collection of options to meet your lighting control needs from traditional switches to wireless and smart home controllers. Our priority is to ensure that your experience with us is seamless and enjoyable; thus, we offer fast and free shipping on most of our products. In addition, our team is always available to assist with any inquiries or product-related questions that you may have. In conclusion, our LED lighting company is committed to providing you with quality products, exceptional customer service, and affordable pricing. Contact us today and let us help you brighten your space while saving you money and energy.
                </p>
            </section>

        </>
    )
}

export default Lighting