import React from 'react'
import pic from '../img/service-3.jpg'
import { Carousel } from 'react-bootstrap';
import material1 from '../img/door2.png';
import material2 from '../img/door1.jpg';

const Doors = () => {
  return (
    <>
            <div style={{ position: "relative", width: "100%" }}>
                <img style={{ height: "70vh", width: "100%" }} src={pic} alt='' />
                <h1 style={{
                    fontSize: "50px",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    color: "white",  // Optional: make sure the text is visible over the image
                    backgroundColor: "rgba(0, 0, 0, 0.5)",  // Optional: add a background for better readability
                    padding: "10px", // Optional: add some padding around the text
                }}>BIFOLD DOORS</h1>
            </div>

            <section style={{ margin: "40px" }}>
                <h2 style={{ fontWeight: "700" }}>Product Overview</h2>
                <p style={{ fontSize: "18px", lineHeight: "1.6" }}>
                Maximize your space with our stylish and functional Bi-Fold Doors. Designed for smooth operation and modern appeal, these doors provide a seamless transition between indoor and outdoor areas, enhancing both the aesthetics and utility of your spaces.
                </p>
            </section>
            <div className='building justify-content-center' style={{ width: "100%", display: "flex" }}>
                <Carousel 
                style={{ width: "55%" }}
                interval={3000}
                >
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={material1}
                            alt="First slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={material2}
                            alt="First slide"
                        />
                    </Carousel.Item>
                </Carousel>
            </div>

            <section style={{ margin: "40px" }}>
                <p style={{ fontSize: "18px", lineHeight: "1.6" }}>
                Bi-fold doors are a popular choice for both interior and exterior spaces because they offer a flexible way to open up a room or connect indoor and outdoor areas. Here are a few key points about them:
                </p>
                <ul>
                    <li><strong>Design and Function: </strong>Bi-fold doors consist of two or more panels that fold up against each other when opened. They slide along a track, making them ideal for spaces where a traditional swinging door might be impractical.</li>
                    <li><strong>Space Efficiency: </strong>Because they fold rather than swing open, bi-fold doors are great for saving space. They’re commonly used in closets, room dividers, and as patio doors.</li>
                    <li><strong>Installation and Maintenance: </strong>Proper installation is crucial for smooth operation. Maintenance typically involves keeping the track clean and lubricated, and checking the hinges and panels for any issues.</li>
                    <li><strong>Benefits: </strong>They maximize natural light and can enhance the flow between spaces. When fully open, they can create a large opening that merges indoor and outdoor areas.</li>
                    <li><strong>Considerations: </strong>It’s important to account for the track and clearance needed for the doors to fold. They also require a bit more space for operation compared to sliding doors.</li>
                </ul>
                <p style={{ fontSize: "18px", lineHeight: "1.6" }}>
                Our commitment to quality and reliability sets us apart from our competitors. We work tirelessly to source only the best materials from reputable manufacturers and suppliers, ensuring that your construction projects are built to the highest standards. 
                </p>
                <p style={{ fontSize: "18px", lineHeight: "1.6" }}>
                At Innov8, we understand the importance of sustainability in the construction industry. That’s why we offer a range of eco-friendly and sustainable materials to help you build projects that are environmentally friendly and reduce carbon emissions. Our team of experts is knowledgeable and experienced in the industry, and we work closely with our customers to understand their specific needs and requirements. Whether you’re a contractor, builder, or homeowner, we’re committed to providing you with personalized service and customized solutions to meet your building material needs.
                </p>
                <p style={{ fontSize: "18px", lineHeight: "1.6" }}>
                We offer competitive prices and a hassle-free experience, with efficient delivery that ensures on-time delivery of ordered products. Our skilled logistics team ensures that your products are delivered safely and securely. At Innov8, we take pride in our reputation for quality, reliability, and professionalism. Our commitment to exceptional customer service combined with our vast selection of products and state-of-the-art facilities make us the premier choice for trading building materials.
                </p>
            </section>

        </>
  )
}

export default Doors