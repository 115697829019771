import React from 'react'
import aboutimg from '../img/about.png'

function About() {
    return (
        <>
            {/* <!-- About Start --> */}
            <div className="container-fluid overflow-hidden py-5 px-lg-0">
                <div className="container about py-5 px-lg-0">
                    <div className="row g-5 mx-lg-0">
                        <div className="col-lg-6 ps-lg-0 wow fadeInLeft" data-wow-delay="0.1s" style={{ minHeight: '400px' }}>
                            <div className="position-relative h-100">
                                <img className="position-absolute img-fluid w-100 h-100" src={aboutimg} style={{ objectFit: 'cover' }} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6 about-text wow fadeInUp" data-wow-delay="0.3s">
                            <h6 className="text-secondary text-uppercase mb-3">About Us</h6>
                            <h1 className="mb-5">Premier Solutions for Modern Building Needs</h1>
                            <p className="mb-5">At Innov8, we go beyond supplying materials – we provide innovative solutions for today’s construction and design challenges. Specializing in Aluminum & Glass Accessories, Modern LED Lighting, State-of-the-Art Automated Pergolas, Slim Partitions, and Smart Glass, we offer high-quality products that meet the demands of contemporary projects. Whether you’re enhancing a commercial space or upgrading your home, trust us to be your premier partner for all your building needs.</p>
                            <div className="row g-4 mb-5">
                                <div className="col-sm-6 wow fadeIn" data-wow-delay="0.5s">
                                    <i className="fa fa-globe fa-3x text-danger mb-3"></i>
                                    <h5>Global Coverage</h5>
                                    <p className="m-0">Our extensive network ensures that no matter where your project is located, you can count on us to deliver the materials you need.</p>
                                </div>
                                <div className="col-sm-6 wow fadeIn" data-wow-delay="0.7s">
                                    <i className="fa fa-check-circle fa-3x text-danger mb-3"></i> 
                                    <h5>Top Quality Products</h5>
                                    <p className="m-0">Our products are selected for their superior durability and reliability, ensuring your projects are built to last.</p>
                                </div>

                            </div>
                            <a href="/services" className="btn btn-danger py-3 px-5">Explore More</a>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- About End --> */}
        </>
    )
}

export default About