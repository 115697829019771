import React from 'react'

function Facts() {
  return (
    <>
            {/* <!-- Fact Start --> */}
    <div className="container-xxl py-5">
        <div className="container py-5">
            <div className="row g-5">
                <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                    <h6 className="text-secondary text-uppercase mb-3">Some Facts</h6>
                    <h1 className="mb-5">Your Trusted Partner for Innovative Building Solutions</h1>
                    <p className="mb-5">At Innov8, we specialize in providing cutting-edge building materials that combine functionality with modern design. From Aluminum & Glass Accessories and Modern LED Lighting to State-of-the-Art Automated Pergolas, Slim Partitions, and Smart Glass, we offer the products you need to bring your visionary projects to life. Trust us to deliver the innovation and quality that set your projects apart.</p>
                    <div className="d-flex align-items-center">
                        <i className="fa fa-headphones fa-2x flex-shrink-0 bg-danger p-3 text-white"></i>
                        <div className="ps-4">
                            <h6>Call for any query!</h6>
                            <h3 className="text-danger m-0">+971 52 418 8072</h3>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="row g-4 align-items-center">
                        <div className="col-sm-6">
                            <div className="bg-danger p-4 mb-4 wow fadeIn" data-wow-delay="0.3s">
                                <i className="fa fa-users fa-2x text-white mb-3"></i>
                                <h2 className="text-white mb-2" data-toggle="counter-up">236</h2>
                                <p className="text-white mb-0">Happy Clients</p>
                            </div>
                            <div className="bg-secondary p-4 wow fadeIn" data-wow-delay="0.5s">
                                <i className="fa fa-ship fa-2x text-white mb-3"></i>
                                <h2 className="text-white mb-2" data-toggle="counter-up">1130</h2>
                                <p className="text-white mb-0">Complete Projects</p>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="bg-success p-4 wow fadeIn" data-wow-delay="0.7s">
                                <i className="fa fa-star fa-2x text-white mb-3"></i>
                                <h2 className="text-white mb-2" data-toggle="counter-up">654</h2>
                                <p className="text-white mb-0">Customer Reviews</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- Fact End --> */}
    </>
  )
}

export default Facts