import React from 'react'
import logo from '../img/trd-logo.png'

function Navbar() {
    return (
        <>
            {/* Navbar Start */}
            <nav className="navbar navbar-expand-lg bg-white navbar-light shadow border-top border-5 border-danger sticky-top p-0">
                <a href="/" className="navbar-brand  d-flex align-items-center px-4 px-lg-5">
                    {/* <h2 className="mb-2 text-white">Innov8</h2> */}
                    <img className='img-fluid mylogo' style={{height:"100px"}} src={logo} alt='logo'/>
                </a>
                <button type="button" className="navbar-toggler me-4" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" >
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarCollapse">
                    <div className="navbar-nav ms-auto p-4 p-lg-0">
                        <a href="/" className="nav-item nav-link active">Home</a>
                        <a href="/about" className="nav-item nav-link">About</a>
                        <a href="/services" className="nav-item nav-link">Services</a>
                        <a href="/contact" className="nav-item nav-link">Contact</a>
                    </div>
                    <h4 className="m-0 pe-lg-5 d-none d-lg-block"><i className="fa fa-headphones text-danger me-3"></i>+971 52 418 8072</h4>
                </div>
            </nav>
            {/* Navbar End */}
        </>
    )
}

export default Navbar