import React from 'react'
import pic from '../img/service-1.jpg'
import material1 from '../img/anga1.jpg';
import material2 from '../img/anga2.jpg';
import material3 from '../img/anga3.jpg';
import material4 from '../img/anga4.jpg';
import material5 from '../img/anga5.jpg';

import { Carousel } from 'react-bootstrap';

const Aluminium = () => {

    const items = [
        { src: material1 },
        { src: material2 },
        { src: material3 },
        { src: material4 },
        { src: material5 },
      ];

    return (
        <>
            <div style={{ position: "relative", width: "100%" }}>
                <img style={{ height: "70vh", width: "100%" }} src={pic} alt='' />
                <h1 style={{
                    fontSize: "50px",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    color: "white",  // Optional: make sure the text is visible over the image
                    backgroundColor: "rgba(0, 0, 0, 0.5)",  // Optional: add a background for better readability
                    padding: "10px", // Optional: add some padding around the text
                }}>Aluminium & Glass Accessories</h1>
            </div>

            <section style={{ margin: "40px" }}>
                <h2 style={{ fontWeight: "700" }}>Product Overview</h2>
                <p style={{ fontSize: "18px", lineHeight: "1.6" }}>
                    Enhance your projects with our top-quality Aluminum & Glass Accessories, designed for durability and modern aesthetics. Perfect for both functional and decorative applications, these products bring a sleek, contemporary touch to any space.
                </p>
            </section>

            {/* <table>
                <tbody>
                    <tr>
                        <td><img src={material1} style={{ height: "150px" }} className='img-fluid' alt="Floor Springs" /><br />Floor Springs</td>
                        <td><img src={material2} style={{ height: "150px" }} alt="Shower Enclosure Sliding System" /><br />Shower Enclosure Sliding System</td>
                        <td><img src={material3} style={{ height: "150px" }} alt="Door Handles" /><br />Door Handles</td>
                    </tr>
                    <tr>
                        <td><img src={material4} style={{ height: "150px" }} alt="Latch & Ball Locks" /><br />Latch & Ball Locks</td>
                        <td><img src={material5} style={{ height: "150px" }} alt="Glass Spigot" /><br />Glass Spigot</td>
                        <td><img src={material6} style={{ height: "150px" }} alt="Click & Push Locks" /><br />Click & Push Locks</td>
                    </tr>
                    <tr>
                        <td><img src={material7} style={{ height: "150px" }} alt="Door Hinges" /><br />Door Hinges</td>
                        <td><img src={material8} style={{ height: "150px" }} alt="Curtain Wall Handle" /><br />Curtain Wall Handle</td>
                        <td><img src={material9} style={{ height: "150px" }} alt="Sliding Door Rollers" /><br />Sliding Door Rollers</td>
                    </tr>
                </tbody>
            </table> */}

<div className='building justify-content-center' style={{ width: "100%", display: "flex" }}>
<Carousel style={{width: "55%"}}>
      {items.map((item, index) => (
        <Carousel.Item key={index}>
          <img
            className="d-block w-100"
            src={item.src}
            alt="pic"
            style={{ height: '500px'}}
          />
        </Carousel.Item>
      ))}
    </Carousel>
</div>
            <section style={{ margin: "40px" }}>
                <p style={{ fontSize: "18px", lineHeight: "1.6" }}>
                    Innov8 is specialized in dealing with Aluminum & Glass hardware and accessories, we deal with various brands like GCC, Domus, Dormakaba, Dormate, Devon, Yale, Giesse, Tristosil, MAB, Union etc apart from these innov8 is sole distributor of Zita, Aluminate, Axcent, Apex systems.
                </p>
                <p style={{ fontSize: "18px", lineHeight: "1.6" }}>
                    We deal with wide variety of products such as Floor springs, patch fittings, shower enclosure system, slim office glass partitions, glass railing system & accessories. We also deal with Bi fold door system based on German Technology.
                </p>
                <p style={{ fontSize: "18px", lineHeight: "1.6" }}>
                    At the most affordable prices on the UAE market, Innov8 offers an extensive selection of hardware accessories, swing and curtain wall handles, knobs, finger latch, door hinges, single and double wheel rollers, and other items for aluminum windows and doors.
                </p>
                <p style={{ fontSize: "18px", lineHeight: "1.6" }}>
                    <strong>Floor Springs: </strong>
                    The ZTS 65 offers a particularly shallow design and all the benefits of a decreased installation depth. The floor springs are effectively customized to suit the unique standards governing floor springs for double action doors. The ZTS 65 can satisfy almost any application need with its two closing ranges and selection of models, all the while offering the dependability that comes with guaranteed quality from Zita.
                    The ZTS 65's useful clamp fixing mechanism guarantees a tight and safe fit within the cement box.
                    Seamless closure process essentially impervious to temperature. Easy door operation is ensured by low effort requirements. Integral single-point hold-open at 90° option. We can also offer ZTS75, ZTS120, ZTS250
                </p>
                <p style={{ fontSize: "18px", lineHeight: "1.6" }}>
                    <strong>Glass Balustrade: </strong>
                    When it comes to glass balustrade or glass railings, Innov8 has a wide selection of products for you to choose.
                    The frameless glass balustrade gives a niche look, saves space, modern and stylish for your project where it be villa, apartment and commercial space.
                </p>
                <p style={{ fontSize: "18px", lineHeight: "1.6" }}>
                    <strong>Patch fittings: </strong>
                    For both residential and commercial projects, Innov8 has one of the widest selections of patch fittings. Toughened glass and glass architectural hardware work flawlessly together in patch fittings. We provide high-quality materials that have a long lifespan and attractive appearance thanks to our large assortment.
                </p>
            </section>

        </>
    )
}

export default Aluminium