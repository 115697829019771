import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Lighting from './pages/Lighting';
import Building from './pages/Building';
import Services from './components/Services';
import Quote from './components/Quote';
import Pergola from './pages/Pergola';
import Aluminium from './components/Aluminium';
import Doors from './pages/Doors';
import Louvers from './pages/Louvers';
import Glass from './pages/Glass';

function App() {
  return (
    <>
    <Navbar />
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path='/about'  element={<About />} />
        <Route path='/lighting'  element={<Lighting />} />
        <Route path='/building' element={<Building />} />
        <Route path='/services' element={<Services />} />
        <Route path='/contact' element={<Quote />} />
        <Route path='/pergola' element={<Pergola />} />
        <Route path='/aluminium' element={<Aluminium />} />
        <Route path='/doors' element={<Doors />} />
        <Route path='/louvers' element={<Louvers />} />
        <Route path='/glass-partitions' element={<Glass />} />

        {/* <Route path="*" element={<NotFound />} /> */}
      </Routes>
    </Router>
    <Footer />
    </>
  );
}

export default App;
