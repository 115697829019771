import React from 'react'
import pic from '../img/pergola-0.jpg'
import { Carousel } from 'react-bootstrap';
import material1 from '../img/pergola1.png';
import material2 from '../img/pergola2.jpg';
import material3 from '../img/pergola3.mp4';
import material4 from '../img/pergola4.png';

const Pergola = () => {
    return (
        <>
            <div style={{ position: "relative", width: "100%" }}>
                <img style={{ height: "70vh", width: "100%" }} src={pic} alt='' />
                <h1 style={{
                    fontSize: "50px",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    color: "white",  // Optional: make sure the text is visible over the image
                    backgroundColor: "rgba(0, 0, 0, 0.5)",  // Optional: add a background for better readability
                    padding: "10px", // Optional: add some padding around the text
                }}>Motorized Louvered Pergola </h1>
            </div>

            <section style={{ margin: "40px" }}>
                <h2 style={{ fontWeight: "700" }}>Product Overview</h2>
                <p style={{ fontSize: "18px", lineHeight: "1.6" }}>
                    A sleek and timeless design. Select from a range of customizable features, including fans and LED lights. The Classic Pergola features 90-degree adjustable blades, allowing for optimal functionality throughout the day.
                </p>
            </section>
            <div className='building justify-content-center' style={{ width: "100%", display: "flex" }}>
                <Carousel
                    style={{ width: "55%" }}
                    interval={null}
                >
                    <Carousel.Item>
                        <video className="d-block w-100" style={{ height: "500px" }} controls>
                            <source src={material3} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={material1}
                            alt="First slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={material2}
                            alt="First slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={material4}
                            alt="Third slide"
                        />
                    </Carousel.Item>
                </Carousel>
            </div>

            <section style={{ margin: "40px" }}>
                <p style={{ fontSize: "18px", lineHeight: "1.6" }}>
                    Our Classic Motorized Louvered Pergola offers a perfect blend of timeless elegance, functional design, and low-maintenance materials, all powered by the industry’s most reliable louver mechanism.
                </p>
                <p style={{ fontSize: "18px", lineHeight: "1.6" }}>
                    The Somfy remote control system operates a linear piston, providing precise and effortless louver adjustment in any weather. With a corrosion-resistant extruded aluminum structure and louvers, you can enjoy years of worry-free use, backed by a robust warranty.
                </p>
                <p style={{ fontSize: "18px", lineHeight: "1.6" }}>
                    The Classic Pergola comes in a variety of colors, and features a sleek, modern design that seamlessly integrates into any setting. Most colors have a textured finish, with white available in semi-gloss. Customize your space with optional retractable ceiling screens, pivot privacy shutters, and dimmable LEDs to create your ideal retreat.
                </p>
                <p style={{ fontSize: "18px", lineHeight: "1.6" }}>
                    Key Features:
                </p>
                <ul>
                    <li>Corrosion-resistant, maintenance-free aluminum components</li>
                    <li>90° remote-controlled louver rotation for optimal light and airflow</li>
                    <li>100% weatherproof when closed</li>
                    <li>Concealed, worry-free water drainage system within corner posts</li>
                    <li>Optional matching screens and shades</li>
                </ul>
            </section>

        </>
    )
}

export default Pergola